<template>
  <b-modal id="modal-company" hide-footer size="lg">
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("companies.new") : $t("companies.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="center">
            <b-col sm="12">
              <div class="text-center mb-4" style="cursor: pointer">
                <b-img
                  class="mt-2 avatar"
                  id="pick-image"
                  style="max-width: 150px"
                  :src="
                    preview
                      ? preview
                      : require('@/assets/images/logo/placeholder.png')
                  "
                ></b-img>
                <br />
                <label class="mt-1 pr-1">Cargar avatar</label>
                <avatar-cropper
                  :upload-handler="selectedFile"
                  @changed="setFileName"
                  trigger="#pick-image"
                  :labels="{
                    submit: $t('app.accept'),
                    cancel: $t('app.cancel'),
                  }"
                  :cropper-options="{
                    aspectRatio: 0,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: true,
                    zoomable: true,
                  }"
                />
              </div>
            </b-col>
            <b-col md="12">
              <b-row align-v="center">
                <!-- NAME -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('companies.name')"
                  label-for="name"
                >
                  <ValidationProvider
                    :name="$t('companies.name')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="data.name"
                      type="text"
                      :placeholder="$t('companies.name-placeholder')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
            <b-col md="6" sm="12">
              <b-row align-v="center">
                <!-- RUT -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('companies.rut')"
                  label-for="rut"
                >
                  <ValidationProvider
                    :busines-name="$t('companies.rut')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="data.rut"
                      type="text"
                      :placeholder="$t('companies.rut-placeholder')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
            <b-col md="6" sm="12">
              <b-row align-v="center">
                <!-- BUSINESS_NAME -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('companies.business-name')"
                  label-for="busines-name"
                >
                  <ValidationProvider
                    :busines-name="$t('companies.business-name')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="data.business_name"
                      type="text"
                      :placeholder="$t('companies.business-name-placeholder')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="companiesLoading"
            variant="success"
            type="submit"
          >
            <span v-if="companiesLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-company')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "CreateOrUpdateCompany",
  components: {
    AvatarCropper,
  },
  data() {
    return {
      fileSelected: null,
      preview: "",
      data: {
        id: null,
        name: null,
        business_name: null,
        rut: null,
        avatar: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "createOrUpdateCompany",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.preview = item.avatar;
        this.data = {
          id: item.id,
          name: item.name,
          business_name: item.business_name,
          rut: item.rut ? item.rut : null,
        };
      }
      this.$bvModal.show("modal-company");
    },
    setFileName(file) {
      this.fileSelected = file;
      this.data.avatar = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.data.avatar = file;
    },
    async onSubmit() {
      const resp = await this.saveForm(this.data);
      this.$bvModal.hide("modal-company");
      this.$emit("refresh");
      if (resp?.status === 201 || resp?.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("companies.success-created")
            : this.$t("companies.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.preview = null;
      this.fileSelected = null;

      this.data = {
        id: null,
        name: null,
        business_name: null,
        avatar: null,
        rut: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      companiesLoading: "companiesLoading",
    }),
  },
};
</script>
